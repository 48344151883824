@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Open Sans', serif;
  padding-top: 54px;
  color: #646b73;
}

@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', serif;
  font-weight: 700;
  color: #343a40;
}

h1, h2 {
  text-transform: uppercase;
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

.subheading {
  font-weight: 500;
  font-family: 'Saira Extra Condensed', serif;
  font-size: 1.35rem;
}

.skill-icons {
  font-size: 4em;
}

.social-icons a {
  margin: 0 0.3em 0 0;
}

#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  text-transform: uppercase;
}

.resume-separator {
  background-size: cover;
  background-attachment: fixed;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
}

.resume-header {
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
}

#about_separator {
  background-image: url(../img/rod.jpg);
}

#experience_separator {
  background-image: url(../img/denver2.jpg);
}

#education_separator {
  background-image: url(../img/ucdenver2.jpg);
}

#skills_separator {
  background-image: url(../img/programming.jpg);
}

#contact_separator {
  background-image: url(../img/contact.jpg);
}

#articles_separator {
  background-image: url(../img/writing.jpg);
}

#projects_separator {
  background-image: url(../img/projects.jpg);
}

img.icon {
  max-width: 150px;
  max-height: 150px;
}

#map iframe {
  width: 100%;
  height: 425px;
  border:1px solid #ddd;
}

@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh;
  }
  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }
  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }
  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }
  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
}

section.resume-section {
  border-bottom: 1px solid #dee2e6;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

section.resume-section .resume-item .resume-date {
  min-width: none;
}

@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh;
  }
  section.resume-separator {
    min-height: 35vh;
  }
  section.resume-section .resume-item .resume-date {
    min-width: 18rem;
  }
}

@media (min-width: 992px) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}

@media (max-width: 768px) {
  section.resume-separator {
    min-height: 35vh;
  }

  img.icon {
    display: none;
  }

  #map {
    display: none;
  }
}

.bg-primary {
  background-color: #4786b2 !important;
}

.text-primary {
  color: #4786b2 !important;
}

a {
  color: #4786b2;
}

a:hover, a:focus, a:active {
  color: #33627f;
}







@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Rock+Salt&family=Source+Code+Pro:wght@400;700&display=swap');


//$font-face { font-family: Joystix; src: url('joystix.otf'); }

//Colors

$background_color: #e0e0e2;
$content_background_color: white;
$highlight_color: mix($background_color, $content_background_color);

$red_color: #96031A;

$blue_color: #4786b2;
$darkblue_color: darken($blue_color, 20%);

$nav_color: $blue_color;
$nav_tab_color: $darkblue_color;
$nav_highlight_color: white;
$nav_border_color: darken($blue_color, 37%);
$sidebar_color: #f7f7f8;
$text_color: #1D1A05;

$header_color: $red_color;
$link_color: $blue_color;

$pullquote_color: $red_color;
$blockquote_border_color: darken($background_color, 15%);


//$calendar_color: $background_color - #333;
$calendar_color: $blue_color;
$calendar_background_color: $background_color;
$separator_color: darken($background_color, 19%);

@function black($opacity){
  @return rgba(0,0,0,$opacity)
}
@function white($opacity){
  @return rgba(255,255,255,$opacity)
}

@mixin gradient {
  background: -moz-linear-gradient(top, $highlight_color, 0%, $background_color 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,transparentize($highlight_color, 0)), color-stop(100%,$background_color)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $highlight_color 0%,$background_color 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,   $highlight_color 0%,$background_color 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,   $highlight_color 0%,$background_color 100%); /* IE10+ */
  background: linear-gradient(to bottom,   $highlight_color 0%,$background_color 100%); /* W3C */
}

@mixin trans-gradient {
  background: -moz-linear-gradient(top, transparentize($highlight_color, 0), 0%, transparentize($highlight_color, 1.0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,transparentize($highlight_color, 0)), color-stop(100%,transparentize($highlight_color, 1.0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  transparentize($highlight_color, 0) 0%,transparentize($highlight_color, 1.0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  transparentize($highlight_color, 0) 0%,transparentize($highlight_color, 1.0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  transparentize($highlight_color, 0) 0%,transparentize($highlight_color, 1.0) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  transparentize($highlight_color, 0) 0%,transparentize($highlight_color, 1.0) 100%); /* W3C */
}


@mixin rounded-corners ($radius: 5px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin glow($color: $blockquote_border_color) {
  -moz-box-shadow: 0px 0px 5px $color;
  -webkit-box-shadow: 0px 0px 5px $color;
  box-shadow: 0px 0px 5px $color;
}

@mixin box-emboss($opacity, $opacity2){
  box-shadow:white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}

@mixin noglow() {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@mixin inner-burn() {
  background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.14) 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.14))); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.14) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.14) 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.14) 100%); /* IE10+ */
  background: radial-gradient(ellipse at center,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.14) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#24000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin box-emboss($opacity, $opacity2){
  box-shadow:white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}

@mixin letterpress($opacity){
  text-shadow:white($opacity) 0 1px 0;
}

@mixin box-shadow($distance: 3px, $blur: 4px, $opacity: 0.30) {
  -webkit-box-shadow: $distance $distance $blur 0px rgba(0, 0, 0, $opacity);
  -moz-box-shadow:    $distance $distance $blur 0px rgba(0, 0, 0, $opacity);
  box-shadow:         $distance $distance $blur 0px rgba(0, 0, 0, $opacity);
}

@mixin pre-wrap() {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

//Layout Stuff

$width: 720px;
$max_width: 920px;
$content_size: percentage(.67);

$content_width: $width;
$sidebar_width: 240px;

$content_padding: 30px;

//$object_spillover: $content_padding / 2;
$object_spillover: 0px;

$image_padding: 5px;

//Fonts

$content_font: "Lora", serif;
$content_weight: 400;
$content_bold_weight: 600;
$header_font: 'Lato', sans-serif;
$code_font_size: 14px;
$code_font_family: "Source Code Pro", monospace;
$code_font: $code_font_size $code_font_family;
$menu_font: $header_font;
$handwriting_font: "Rock Salt", cursive;

$caption_font: $handwriting_font;
$pullquote_font: $handwriting_font;

/* Post Formatting */

#content hr {
  border: none;
  border-bottom: 1px dotted $separator_color;
  margin: 0;
}

$inner_block_width: $content_width - (5 * $content_padding);

.post-listing {
  padding: 1em 0;
}

.post-listing .postheader h1 {
  font-size: 100%;
}

.post-listing .post-preview {
  border-bottom: 1px dotted $separator_color;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.post-listing .post-preview:last-child {
  border-bottom: 0;
}


a.thumb {

  figure {
    float: left;
    margin: $content_padding /2;
    position:relative;
    width:calc(25% - 30px - 2px);
  }

  figure img {
    width: 100%;
    aspect-ratio: 16 / 10;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    @include rounded-corners;
    //@include glow;
    border: 1px solid $link_color;
    margin-bottom: $content_padding /2;
  }

  figcaption {
    display: table-row;
    line-height: 1.5em;
    letter-spacing: .1em;
    padding: 0.5em 1em 0.5em 1em;
    font-size: 0.8rem;
    text-align: center;
    font-family: $caption_font;
    margin-bottom: $content_padding /2;
    height: 4rem;
    overflow: hidden;
  }

}

.post {
  margin:0;
  //padding: $content_padding 0;
  max-width: 1000px;
  //font-family: $content_font;
  font-weight: $content_weight;
  font-size: 100%;
  //letter-spacing: 0.01em !important;
  word-spacing: .15em;
  line-height: 1.55em;
  @include letterpress(.4);
  text-align: left;

  b, strong {
    font-weight: $content_bold_weight;
  }

  p, ol li, ul li{
    margin-top:0;
  }

  li {
    margin-top: .25em;
    margin-bottom: .25em;
  }

  dd, dt, dl, li, ul, ol {
    text-align: left;
  }

  /*
  p:first-child:first-letter {
    font-size: 2em;
    padding: .1em;
  }

        p:first-child {
    text-indent: 0;
  }
  */

  p {
    //  text-indent: 1em;
  }

  li li {
    font-size:1em;
  }

  .postentry {
    p {
      //hyphens: auto;
    }

    blockquote, .chat-transcript, pre {
      // word-spacing: normal;
      // letter-spacing: normal;
      font-size:100%;
      text-align: left;
      //background-color: $highlight_color;
      border: 0;
      margin:$content_padding auto;
      width: $inner_block_width;
      //@include rounded-corners;
      //@include glow;
      p { margin: .5em; }
      hyphens:none;
    }

    blockquote, .chat-transcript {
      padding: 5px;
      hyphens:none;
    }

    blockquote {
      content: attr(data-pullquote);
      width: 80%;
      margin-bottom: $content_padding;
      margin-top: $content_padding;
      //padding: $content_padding /2;
      padding:0;
      text-align: center;
      font-family: $pullquote_font;
      line-height: 1.5em;
      font-size: 1rem;
      color: $pullquote_color;
      //border: 0;
      letter-spacing: 0.1em;
      //background-color: $highlight_color;
      //@include rounded-corners;
      //@include glow;
    }

    .chat-transcript {
      padding-left: 1em;
      padding-right: 1em;

      dd {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    pre code {
      overflow: auto;
      padding: 5px;
      @include rounded-corners;
      hyphens:none;
    }

    blockquote blockquote {
      margin-left:0;
      font-size:1em;
    }

    //.twitter-tweet {
    //  word-spacing: normal;
    //  line-height: 1em;
    //  letter-spacing: normal;
    // margin: 0 auto !important;
    // font-family: "Helvetica Neue",Arial,sans-serif;
    // font-size: 14px;
    // line-height: 18px;
    // text-align: left;
    // max-width: 90% !important;
    // margin-bottom: 1em !important;
    //}

    .twitter-tweet {
      margin: $content_padding auto !important;
    }

    table {
      margin-bottom: 1em;
    }

    table.receipt {
      display: table;
      background-color: $highlight_color;
      border: 0;
      margin:$content_padding auto;
      padding:$content_padding;
      @include rounded-corners;
      //@include glow;
      border-color: $blockquote_border_color;

      th, td {
        padding: .3em .5em;
      }

      thead {
        font-weight: $content_weight;
        border-top:0;
        background-color: $background_color;
      }

      tfoot {
        font-weight: $content_bold_weight;
        border-bottom: 0;
      }
    }

    blockquote.email {
      word-spacing: normal;
      letter-spacing: normal;
      line-height: 16px;
      font-size: 12px;
      font-family: "Lucida Console", Monaco, monospace;
      background-color: white;
      text-align: left;
    }

    pre {
      font: $code_font !important;
      font-size: 100% !important;
      word-spacing: normal;
      letter-spacing: normal;
      hyphens:none;
    }

    .tweet {
      border-color: #cbecfb !important;

      .from {
        font-family: trebuchet ms;
        font-weight: bold;
        color: #4596cd;
        padding: 1px 8px 2px 27px;
        background-color: #f8f8fb;
        background-image: url(/wp-content/themes/flying-machetes2/images/tweet.png);
        background-repeat:no-repeat;
      }

      a, a:hover {
        text-decoration: none !important;
        color: $text_color;
        border: 0;
      }
    }

    code {
      font: $code_font;
      //background-color: transparentize($highlight_color, 0.9);
      background-color: $highlight_color;
      padding: 0 0.2em;
      hyphens: none;
    }

    .code-edit {
      padding: 4px;
      font: $code_font;
      color: black !important;
    }

    code.language-output, code.language-terminal {
      -ms-word-break: break-all;
      word-break: break-all;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      word-wrap: break-word;
    }

    code.language-output {
      font-size: ($inner_block_width - ($content_padding * 2) - 10)/ 50 !important;
    }

    code.language-plaintext {
    }

    pre code {
      background-color: $highlight_color;
    }

    pre code.language-terminal, pre code.language-output {
      margin: 0;
      overflow-x: auto;
      color: $highlight_color !important;
      font: $code_font;
      text-shadow: none;
      padding: 10px;
      background-color: #222;
      @include pre-wrap;

      span {
        color: $highlight_color !important;
      }
    }

    dt {
      font-weight: bold;
      padding: 0;
    }

    dd {
      margin: 0 0 .5em 0;
      padding: 0 0 0 2em;
    }

    figure.image {
      border: 0;
      //background-color: $highlight_color;
      padding: 0;
      //@include rounded-corners;
      //@include glow;
      text-align: center;

      display: table;
      margin: 0;

      img {
        display: table-row;
        width: 100%;
      }

      figcaption {
        display: table-row;
      }

      a {
        border-bottom: 0;
        &:hover { border-bottom: 0; }
      }

      .caption {
        margin: 5px 5px 0 5px;
        line-height: 1.5em;
        letter-spacing: .1em;
        padding: 0.5em 1em 0.5em 1em;
        font-size: 1rem;
        text-align: center;
        font-family: $caption_font;
      }

      img {
        border-radius: 5px;
        //@include glow
      }

      &.captioned img {
        border-radius: 5px;
      }

      &.aligncenter {
        /*max-width: 1px; */
        margin: $content_padding auto $content_padding auto;
        //max-width: 80%;
        max-width: 800px;
        clear:both;
        // img {
        //   max-width: $content_width * .8;
        // }
      }

      &.alignright {
        margin:.5em 0 0.5em $content_padding;
        margin-right:  -$object_spillover;
        float: right;
        //max-width: 35%;
        max-width: 350px;

        // img {
        //   max-width: $content_width * .45;
        // }
      }

      &.alignleft {
        margin:.5em $content_padding .5em  0;
        margin-left:  -$object_spillover;
        float: left;
        //max-width: 35%;
        max-width: 350px;

        // img {
        //   max-width: $content_width * .45;
        // }
      }

    }

    hr {
      margin-bottom: 1em !important;
    }

    h1, h2, h3, h4, h5, h6 {
      //font-weight: normal;
      margin: 1.4em 0 .8em 0;
    }

    //h1 { font-size: 170%; margin-top: 1.8em;}
    //h2 { font-size: 150%; }
    //h3 { font-size: 130%; }

    a {
      border-bottom:1px solid #ddd;
    }

    a:hover {
      border-bottom:1px solid $link_color;
      text-decoration:none;
    }

    .footnotes {
      border-bottom: 1px dotted $separator_color;
      font-size: 75%;
      line-height: 1.2em;

      a.reversefootnote {
        font-size: 60%;
        text-decoration: none;
        border-bottom: 0;
      }
    }

  }


  span[data-pullquote] {
    /*display: block;*/
  }

  span[data-pullquote]:before {
    content: attr(data-pullquote);
    width: 100%;
    min-width: 20%;
    max-width: 30%;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    padding: $content_padding /2;
    text-align: center;
    letter-spacing: .1em;
    font-family: $pullquote_font;
    //font-size: 1.3em;
    //font-size: 80%;
    font-size: 1rem;
    color: $pullquote_color;
    //border: 0;
    //background-color: $highlight_color;
    //@include rounded-corners;
    //@include glow;
  }

  span[data-pullquote].right:before {
    float: right;
    margin-right: -$object_spillover;
    margin-left: $content_padding;
  }

  span[data-pullquote].left:before {
    float: left;
    margin-right: $content_padding;
    margin-left: -$object_spillover;
  }

  .equation {
    margin: 1em auto;
    text-align: center;
  }

  .inline-equation {
  }

  #thumbnails {
    margin-left: -($content_padding /2);
    margin-right: -($content_padding /2);
  }

  .thumbnail{
    width:calc(25% - 30px - 2px);
    aspect-ratio: 16 / 10;
    float: left;
    margin: $content_padding /2;
    position:relative;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    @include rounded-corners;
    //@include glow;
    border: 1px solid $link_color;

    //&::after {
    //  background: rgb(255,255,255);
    //  background: linear-gradient(180deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.8015800070028012) 50%, rgba(255,255,255,0) 100%);
    //  display:block;
    //  width:100%;
    //  height:100%;
    //  content: " ";
    //  @include rounded-corners;
    //}

    h1 {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $background_color;
      //text-shadow: -1px 1px 0 mix($link_color, white, 25%),
      //1px 1px 0 mix($link_color, white, 25%),
      //1px -1px 0 mix($link_color, white, 25%),
      //-1px -1px 0 mix($link_color, white, 25%),
      //2px 2px 5px black;
      width: 100%;
      text-align: center;
      line-height: 2em;
      //transform: translateY(-50%); /* doesn't work in IE9 and older I'm affraid */
      margin: 0;
      font-family: $caption_font;
      padding: $content_padding / 2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      letter-spacing: .1em;
      box-sizing: border-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      font-size: 90%;
      -webkit-box-orient: vertical;
      border-radius: 0 0 5px 5px ;
      -webkit-border-radius: 0 0 5px 5px;
      -moz-border-radius: 0 0 5px 5px ;
      background-color: $link_color;
      text-transform: initial;

    }
  }

  h1 { font-size: 3rem; }
  h2 { font-size: 2.6rem; }
  h3 { font-size: 2.4rem; }
  h4 { font-size: 2rem; }
  h5 { font-size: 1.5rem; }
  h6 { font-size: 1.2rem; }

  h1, h2, h3, h4, h5, h6 {
    clear:both;
  }

  a:hover .thumbnail {
    border-color: $darkblue_color;

    h1 {
      color: white;
      background-color: $darkblue_color;
    }
  }

  .postheader {
    width: 100%;
    font-size: 80%;
    font-family: $header_font;
    font-weight: 400;
    margin-bottom: 1.5em;

    .posttitle {
      line-height: 1.2em;
      letter-spacing: -.01em;
      word-spacing: .1em;
      text-decoration: none !important;
      color: #46a;
      margin: 0 0;

      a {
        color: $link_color;
        &:hover { text-decoration: none }
      }
    }

    h1 { font-size: 3.5rem; }
    h2 { font-size: 3rem; }
    h3 { font-size: 1.4rem; }
    h4 { font-size: 1.2rem; }
    h5 { font-size: 1.1rem; }
    h6 { font-size: 1rem; }

    .postmeta {
      height: 20px;
      line-height: 20px;

      .category {
        height: 20px;
        float: left;
      }

      .comments {
        height: 20px;
        float: left;
        margin: 0 10px;
      }
    }

    .postdate {
      width: 48px;
      height: 48px;
      border: 1px solid $calendar_color;
      float: right;
      text-align: center;
      margin: 0 0 5px 5px;
      text-shadow: none;
      background-color: $calendar_background_color;

      .month {
        height: 20px;
        line-height:20px;
        font-size: 130%;
        background-color: $calendar_color;
        color: $calendar_background_color;
      }

      .day {
        height: 30px;
        line-height: 25px;
        font-size: 160%;
        color: $calendar_color;
      }
    }
  }

  .toc {
    font-family: $header_font;
    padding: $content_padding/2 $content_padding/2 $content_padding/2 $content_padding;
    float: right;
    font-size: 90%;
    line-height: 1.1em;
    font-weight: 400;
    letter-spacing: 0;
    word-spacing: 0;
    text-align: left;
    margin: 0.5em 0 $content_padding $content_padding;
    margin-right:  -$object_spillover;
    min-width: 160px;
    max-width: 25%;
    border: 0;
    background-color: $highlight_color;
    @include rounded-corners;
    //@include glow;

    a { border: none }
    h1 {
      margin: 0 0 1em 0;
      font-size: 120%;
      font-weight: bold;
      color: $text_color;
      text-align: center;
      line-height: 1em;
    }
  }

  .toc ol {
    margin: 0 5px 0 -7px;
    padding-left: 10px;
    list-style-type: disc;
    li { margin: .3em 0; }
  }

  .toc ol ol {
    display: none;
    list-style-type: none;
    margin-left: -10px;
  }

  .toc ol ol ol {
    display: none;
    list-style-type: none;
    margin-left: 0px;
  }

}


dl.links {
  dt {
    font-weight: normal !important;
    padding-left: 5em !important;
    text-indent: -4em;
    margin-top: .8em;

    a { /* font-weight: bold !important; */}
  }
  dd {
    padding-left: 4em !important;
  }
}

.postmetadata {
  margin:1em 0;
  font-size:110%;
  color:#888;
  text-align:justify;
}

.navigation {
  font-size:110%;
  padding: $content_padding 0;

  .alignleft { float: left; }
  .alignright { float: right; }
}

.cleaner {
  clear: both;
  font-size: 1px;
}

.resume {
  @include glow;
  border: 1px solid $blockquote_border_color;
  @include rounded-corners;
  font-size: 80%;
  padding: 3em;
  background-color: $highlight_color;
  font-family: "Trebuchet MS";
  line-height: 1.2em;
  letter-spacing: normal;
  word-spacing: normal;

  hr {
    margin: 0;
    padding: 0;
    height: 1px;
    background-color: #ccc;
    color: #ccc;
    border: 0 !important;
  }

  //h2 {
  //  font-size: 85% !important;
  //  font-weight: bold !important;
  //  margin: .8em 0;
  //  color: $text_color;
  //}

  ul {
    list-style-type: none;
    margin-left: -3em;

    li {
      margin: .4em 0;
    }

    ul {
      margin-left: -1.5em;
      list-style-type: disc;
      font-size: 82%;
      li { margin: .1em 0; }

      LI:last-child {
        margin-bottom: 1em;
      }
    }
  }
}

.EmbeddedTweet {
  -moz-box-shadow: 0px 0px 5px #000 !important;
  -webkit-box-shadow: 0px 0px 5px #000 !important;
  box-shadow: 0px 0px 5px #000 !important;
}


@media screen and (max-width: ($width - 1)) {

  #navlist li.cat-item {
    display: none;
  }

  #page {
    margin-left: 0;
  }

  #navcontainer {
    width: 100%;
  }

  #navlist {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  .toc {
    display: none;
  }


  .post .postentry table.image.aligncenter,
  .post .postentry blockquote,
  .post .postentry pre {
    max-width: 96%;
  }

  .post .postentry .image.aligncenter {
    img, embed {
      max-width: 100%;
      height: auto;
    }
  }




}


@media screen and (max-width: (600px)) {
  body {
    font-size: 52%;
  }

  .post {
    text-align: left;
  }

  .post .postentry .thumbnail h1 {
    font-size: 90%;
    top: 0;
    bottom: 0;
    @include rounded-corners;
    background-color: rgba($highlight_color, .9);
  }

  .post .postentry .image {

    &.aligncenter {
      // img {
      //   max-width: $content_width * .4;
      // }
    }

    &.alignright {
      float: none;
      margin: 0 auto;
      margin: $content_padding auto $content_padding auto;
      // img {
      //   max-width: $content_width * .2;
      // }
    }

    &.alignleft {

      float: none;
      margin: 0 auto;
      margin: $content_padding auto $content_padding auto;

      // img {
      //   max-width: $content_width * .2;
      // }
    }
  }

}

@media screen and (max-width: (420px)) {

  .post .postentry .thumbnail h1 {
    font-size: 60%;
    overflow: hidden;
  }

  .post .postentry figure.image {
    &.aligncenter {
      margin:1em auto;
      float: none;
      width: 90% !important;
      max-width: 90%;

      // img {
      //   max-width: $content_width * .45;
      // }
    }

    &.alignright {
      margin:1em auto;
      float: none;
      width: 90% !important;
      max-width: 90%;

      // img {
      //   max-width: $content_width * .45;
      // }
    }

    &.alignleft {
      margin:1em auto;
      float: none;
      width: 90% !important;
      max-width: 90%;

      // img {
      //   max-width: $content_width * .45;
      // }
    }

  }


}
